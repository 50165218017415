import { Button, FormLayout } from "../../components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateIntakeFunnel } from "../../features/intakefunnel/intakeFunnelSlice";
import useLoadScript from "../../hooks/useLoadScript";
import { trackLead } from "../../features/track/track";
import enrollmentService from "../../features/enroll/enrollmentService";

export function FunnelIntake({ setCurrentStep, userPath }) {
  const dispatch = useDispatch();

  const { recordId, formData } = useSelector((state) => state.intakefunnel);

  const {
    handleSubmit,
    register,
    setValue,
    setError,
    trigger,
    formState: { isValid, errors },
  } = useForm({});

  useLoadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyALlL-KEK1J7MIxY3xhATz-U-RwPqaWkcs&libraries=places&callback=initAutocomplete', 'google-maps-script');

  window.initAutocomplete = () => {
      const input = document.getElementById('street_address');
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        types: ['address'],
        componentRestrictions: { country: 'us' },
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const addressComponents = place.address_components;

        const getAddressComponent = (type) => {
          return addressComponents.find(component => component.types.includes(type))?.short_name || '';
        };

        setValue('street_address',
          getAddressComponent('street_number') + ' ' + getAddressComponent('route')
          + ', ' + getAddressComponent('locality') + ', ' + getAddressComponent('administrative_area_level_1') + ' ' + getAddressComponent('postal_code')
        );
        setValue('address_line1', getAddressComponent('street_number') + ' ' + getAddressComponent('route'));
        setValue('city', getAddressComponent('locality'));
        setValue('state', getAddressComponent('administrative_area_level_1'));
        setValue('postal_code', getAddressComponent('postal_code'));
        trigger(); // Validate the form to update the form state
      });
  }

  const onSubmit = (data) => {
    const request = {
      ...formData,
      id: recordId,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone_number: data.phone_number,
      sms_opt_in: data.sms_opt_in,
      street_address: data.street_address,
      address_line1: data.address_line1,
      address_line2: data.address_line2,
      city: data.city,
      state: data.state,
      zip_code: data.postal_code,
    };

    const previousStep = userPath[userPath.length - 2];

    dispatch(updateIntakeFunnel(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        trackLead();
        
        // Enroll the user if they are not out of market
        if ( !formData.out_of_market ) {
          enrollmentService.enroll({
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
          })
        }

        // If the user came from FunnelStep2, they had answered "I'm not sure"
        // and we'll continue to the "Give me more details" end card.
        if ( previousStep === "FunnelStep2") {
          setCurrentStep("FunnelEndCard2")
        } else if (previousStep === "FunnelStep4") {
          // If the user came through the location card and specified they are out of market,
          // they'll continue to the "Out of market" end card.
          if ( formData.out_of_market ) {
            setCurrentStep("FunnelEndCard3");
          }
          // Otherwide they'll continue to the standard end card.
          else {
            setCurrentStep("FunnelEndCard4");
          }
        }
        // As I am typing this comment, I'm not sure what state gets the user to continue
        // to the calendly booking page.
        else {
          setCurrentStep("FunnelCalendly");
        }
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <div className="mt-8">
            <h1 className="text-4xl sm:text-5xl text-center font-bold tracking-wide leading-tight text-neutral-900">
              We're looking forward to speaking with you!
            </h1>
          </div>
        }
        mainComponent={
          <>
            <input
              type="hidden"
              name="address_line1"
              id="address-line1"
              {...register("address_line1", { required: false })}
            />
            <input
              type="hidden"
              name="address_line2"
              id="address-line2"
              {...register("address_line2", { required: false })}
            />
            <input
              type="hidden"
              name="city"
              id="city"
              {...register("city", { required: false })}
            />
            <input
              type="hidden"
              name="state"
              id="state"
              {...register("state", { required: false })}
            />
            <input
              type="hidden"
              name="postal_code"
              id="postal_code"
              {...register("postal_code", { required: false })}
            />
          <div className="px-6 md:px-0 lg:pt-4 lg:pl-4 my-6">
            <div className="grid mx-auto max-w-2xl grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    autoFocus="autofocus"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                    {...register("first_name")}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                    {...register("last_name")}
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="street_address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Street address
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="street_address"
                    id="street_address"
                    autoComplete="street_address"
                    autoFocus="autofocus"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                    {...register("street_address", { required: true })}
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                    {...register("email", { required: true })}
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Phone Number
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor="country" className="sr-only">
                      Country
                    </label>
                    <select
                      id="country"
                      name="country"
                      autoComplete="country"
                      className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm"
                    >
                      <option>US</option>
                      <option>CA</option>
                      <option>EU</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    name="phone-number"
                    id="phone-number"
                    className="block w-full rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                    placeholder="+1 (555) 987-6543"
                    {...register("phone_number", { required: true })}
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <div>
                  <input
                    type="checkbox"
                    name="sms_opt_in"
                    id="sms_opt_in"
                    className="mr-2"
                    {...register("sms_opt_in")}
                  />
                  <label htmlFor="sms_opt_in" className="mr-2 text-xs">
                  By checking this box, I agree to Revival Homes' <a href="https://www.revivaladu.com/legal/terms-of-service">Terms and Conditions</a> and <a href="https://www.revivaladu.com/legal/privacy-policy">Privacy Policy</a> and I give consent to Revival Homes to send SMS messages related to its services. Messaging frequency is variable. For HELP, text back HELP. To unsubscribe, text back STOP. Standard message and data rates apply.
                  </label>
                </div>
              </div>
            </div>
          </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-4 md:mt-5 flex justify-center sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
              >
                Continue
              </Button>
            </div>
          </>
        }
      />
    </>
  );
}

